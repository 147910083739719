:root {
    --font-family: "Inter", "Inter-fallback", sans-serif;
    --text-colour: #000000;
    --secondary-text-colour: #515154;
    --background-colour: #fafafa;
    --selected-bg-color:#e7e7e8;
    --link: #000000;
    --link-hover: #000000;
    --link-active: #000000;
    --link-bg: #beff5c;
    --block-space--tablet: 332px;
    --block-space--desktop: 352px;
    --video-border: #eaeaea;
}